import React from "react";
import "./About.scss";
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import NavBar from "../../Component/Navbar/navbar";
export default function AboutUs() {
  return (
    <>
      <NavBar />
      <div className="aboutUsSection">
        <div className="abtpgfstcontsec">
          <div className="centertext herosection-text">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h1>We make goodness in your life</h1>
                  <p>
                    We hope that this platform can allow the current and future
                    generations to access the work done by the rishis for their
                    benefit and upliftment.&nbsp; We just started and we pray
                    that the god in everyone helps us get there.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="abtpgsndcontsec">
          <div className="container">
            <h2>Our Mission &amp; Vision</h2>
            <div className="row">
              <div className="col-lg-6">
                <div className="missionvisiondatasec">
                  <h3>Mission</h3>
                  <p>
                    Create an ecosystem of products and services that deliver
                    the profound riches in sanatana dharma to current and future
                    generations
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="missionvisiondatasec">
                  <h3>Vision</h3>
                  <p>
                    Making spiritual journeys more accessible, interesting and
                    successful for all
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="abtpgthrdcontsec">
          <div className="container">
            <h2>What led to starting karishye?</h2>
            <p>
              Sanatana dharma literally means ‘a way of leading life that is
              coming from time immemorial’ . This dharma (which is largely but a
              tad incorrectly referred to as a religion called Hinduism) puts
              forth a set of rituals and practices, which when followed rightly
              by a household, has the ability to bring great prosperity, health
              and happiness. At the same time, these rituals slowly start
              creating an state of persistent inner peace, calmness and
              sensitivity towards all other life forms and start filling one’s
              life with content and gratitude.
            </p>
            <div className="midabtheight" />
            <p>
              There are more than a billion people on the planet, who are
              already within the fold of ‘sanatana dharma’ and follow several
              rituals in the form of festivals, pujas to mark special occasions,
              temple visits etc. Karishye’s main intention is to use modern
              technology and management to bring out the logic and clarity
              behind the various rituals and practices so that they can become
              powerful tools for one’s transformation.
            </p>
            <p>
              Our aim is to create a strong successful tech firm we can attract
              the best talent in the world to work on this problem, not just
              now, but for generations to come. And that excites us more than
              anything else. With Karishye, we hope that a lot more people come
              to appreciate and utilize these vedic rituals for their own
              betterment.
            </p>
            <p>&nbsp;</p>
            <h2
              style={{
                textAlign: "center"}}
            >
              It’s our privilege to serve you!
            </h2>
          </div>
        </div>
      </div>
      <Footer />
      <Whatsapp/>
    </>
  );
}
