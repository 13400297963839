import React from 'react';
import PropTypes from 'prop-types';
const Modal = ({ show, videoId }) => {
  if (!show) {
    return null;
  }

  return (
          <div className="modal-body">
            <iframe
              src={`https://www.youtube.com/embed/${videoId}`}
              style={{ width: '100%', height: '315px' }}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={`YouTube video: ${videoId}`} 
            ></iframe>
          </div>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired, // added the prototype to show to be a boolean and required
  videoId: PropTypes.string.isRequired, // Expect videoId to be a string and required
};

export default Modal;
