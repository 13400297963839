  const seoData = {
     "naamakaranam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/naamakaranam",
      "Keywords": "Pandit For Namakaran Puja, Naamrakaram Pooja, Namakaran Puja Ceremony, Telugu Pujari for Naamakaranam, Naming Ceremony, Puja Samagri For Namakaran",
      "MetaTitle": "Pujari for Naamakaranam | Pandit for Naming Ceremony ",
      "MetaDescription": "Find expert pujari services for Naamakaranam ceremonies at Karishye. Book a skilled priest to perform your baby's naming ceremony with grace and tradition.",
      "H1Tag": "Pujari for Naamakaranam"
     },
     "aksharabhyasam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/aksharabhyasam",
      "Keywords": "Aksharabhyasam Ceremony, Pandit for Aksharabhyasam, Aksharabhyasam Pooja Pandit",
      "MetaTitle": "Book Telugu Pandit for Aksharabhyasam Puja with Material  ",
      "MetaDescription": "Book a Telugu Pandit for Aksharabhyasam Puja with all the necessary materials. Experience a seamless and traditional ceremony with expert guidance.",
      "H1Tag": "Aksharabhyasam Ceremony"
     },
     "gruhapravesham":{
      "PageURL": "https://www.karishye.co/poojas/telugu/gruhapravesham",
      "Keywords": "Gruhapravesam Pooja, House Warming Ceremony, Gruhapravesam Pooja Services, House Warming Services, ",
      "MetaTitle": "Telugu Pujari for Gruhapravesham | House Warming Services",
      "MetaDescription": "Book a Telugu Pujari for Gruhapravesham and housewarming services in Hyderabad. Enjoy a traditional, auspicious ceremony with expert guidance & complete rituals",
      "H1Tag": "Gruhapravesham"
     },
     "ganapati-homam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/ganapati-homam",
      "Keywords": "Ganapati Homam, Ganesh Puja, Ganapati Homam Pooja Items, Telugu Pujari for Ganapati Homam, Ganapati Homam for House Warming",
      "MetaTitle": "Telugu Pujari for Maha Ganapati Homam Puja in Hyderabad",
      "MetaDescription": "Karishye offers Telugu pujari services for Maha Ganapati Homam puja in Hyderabad. Expert priests, complete puja samagri, and traditional rituals for your needs.",
      "H1Tag": "Ganapati Homam"
     },
     "satyanarayana-vratam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/satyanarayana-vratam",
      "Keywords": "Satyanarayana Pooja, Satyanarayana Pooja Samagri, Telugu Pujari for Satyanarayana Pooja, Satyanarayan Vratam Services, Satyanarayana Vratam Vedapurohit",
      "MetaTitle": "Sri Satyanarayana Vratam - Book Telugu Pujari Online",
      "MetaDescription": "Book a Telugu Pujari for Sri Satyanarayana Vratam with Karishye. Get authentic pooja samagri and expert guidance for your sacred rituals online.",
      "H1Tag": "Sri Satyanarayana Vratam Telugu"
     },
     "rudrabhishekam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/rudrabhishekam",
      "Keywords": "Maha Rudrabhishekam Puja, Rudrabhishekam Pooja, Telugu Pujari for Maha Rudrabhishekam, Cost of Rudrabhishekam, Benefits of Rudrabhishekam",
      "MetaTitle": "Book Expert Telugu Pujari for Maha Rudrabhishekam Puja",
      "MetaDescription": "Book expert Telugu pujaris for Maha Rudrabhishekam Puja in Hyderabad on Karishye. Get top-rated, experienced pujaris for your sacred rituals online.",
      "H1Tag": "Rudrabhishekam pooja at home"
     },
     "navagraha-homam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/navagraha-homam",
      "Keywords": "Navagraha Homam, Navagraha Homa, Navagraha Shanti, Navagraha Puja, Pujari for Navagraha Pujari, Nav Grah Shanti Puja, Navagraha pooja cost",
      "MetaTitle": "Book Telugu Pandits for Navagraha Shanti Homam Online",
      "MetaDescription": "Book Telugu Pandits online for Navagraha Shanti Homam in Hyderabad on Karishye. Expert pujas to balance the nine planets and transform your life.",
      "H1Tag": "Navagraha Homam"
     },
     "varalakshmi-vratham":{
      "PageURL": "https://www.karishye.co/poojas/telugu/varalakshmi-vratham",
      "Keywords": "Varalakshmi Vratham, Varalakshmi Vratham Puja, Varalakshmi Puja Samagri, Pandit for Varalakshmi Vratham, Varalakshmi Pooja Items, Varalakshmi Katha",
      "MetaTitle": "Telugu Pandit for Varalakshmi Vratham Pooja at Best Price",
      "MetaDescription": "Find the best price for Telugu Pandit services for Varalakshmi Vratham Pooja on Karishye. Expert pujaris and quality pooja items are available online.",
      "H1Tag": "Varalakshmi Vratham"
     },
     "punsavanam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/punsavanam",
      "Keywords": "Punsavanam, Punsavan Sanskar, Pandit for Punsavan Sanskar, Baby Shower Ceremony, Pumsavana Sanskara Puja",
      "MetaTitle": "Book Online Pandit for Punsavanam Sanskar Puja Seemantham ",
      "MetaDescription": "Book a Telugu pandit online for Punsavanam Sanskar (Seemantham) Puja at Karishye – ensure a healthy child with authentic and hassle-free puja services.",
      "H1Tag": "Punsavanam Sanskar"
     },
     "garbhaadanam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/garbhaadanam"
     },
     "seemanthonayanam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/seemanthonayanam",
      "Keywords": "Seemanthonayanam, Seemantham Ceremony, Baby Shower Pooja, Pandit for Seemantham Ceremony, Pandit for Baby Shower Pooja, Seemantham Puja, Seemantham Function",
      "MetaTitle": "Seemantham Ceremony | Book Pandit for Baby Shower Puja",
      "MetaDescription": "Book a Telugu pandit for the Seemantham Ceremony at Karishye – your top platform for baby shower puja services, Telugu priests, and pooja items.",
      "H1Tag": "Seemanthonayanam Ceremony"
     },
     "annaprasana":{
      "PageURL": "https://www.karishye.co/poojas/telugu/annaprasana",
      "Keywords": "Annaprasana Puja, Annaprashan Ceremony, Annaprasana in telugu, Annaprasana in English, Annaprasana Pandit, Baby Rice Ceremony",
      "MetaTitle": "Book Annaprasan Puja Pandit | Baby Rice Ceremony - Karishye",
      "MetaDescription": "Book a qualified pandit for your baby's Annaprasan puja with Karishye – the leading platform for online puja services, pujari booking, and pooja items.",
      "H1Tag": "Annaprasana Puja Ceremony"
     },
     "upanayanam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/upanayanam",
      "Keywords": "Upanayanam, Upanayana Samskara, Sacred Thread Ceremony, Upanayanam Samagri Kit, Upanayanam Packages",
      "MetaTitle": "Upanayanam - Book Online Puja and Homam Services",
      "MetaDescription": "Book your Upanayanam ceremony effortlessly with Karishye. Find expert Telugu purohits and all the pooja items you need for a perfect ritual.",
      "H1Tag": "Upanayanama Ceremony",
      "SuggestedURL": "https://www.karishye.co/poojas/telugu/upanayanam-ceremony"
     },
     "shankusthapana":{
      "PageURL": "https://www.karishye.co/poojas/telugu/shankusthapana",
      "Keywords": "Shankusthapana, Laying of Foundation Stone, Bhoomi Pooja, Bedrock Ceremony, Shankusthapana Pooja Samagri, Shankusthapana Packages",
      "MetaTitle": "Book Telugu Purohit for Shankusthapana or Bhoomi Puja ",
      "MetaDescription": "Book a skilled Telugu purohit for Shankusthapana Puja Service on Karishye Across Telangana & AP. Ensure your rituals are performed with precision and devotion.",
      "H1Tag": "Shankusthapana"
     },
     "vaahana-pooja":{
      "PageURL": "https://www.karishye.co/poojas/telugu/vaahana-pooja",
      "Keywords": "Vaahana Pooja, Vehicle Puja, Car Pooja, Vahana Puja Cost, Vehicle Pooja Packages",
      "MetaTitle": "Book Vaahana Puja | Vehicle Pooja Samagri Kit - Karishye",
      "MetaDescription": "Book Vaahana Puja with Karishye and receive a complete Vehicle Puja Samagri Kit. Ensure your vehicle is blessed and protected with our expert services."
     },
     "shashtipoorthi":{
      "PageURL": "https://www.karishye.co/poojas/telugu/shashtipoorthi",
      "Keywords": "Shashtipoorthi Shanthi Ceremony, ",
      "MetaTitle": "Book Telugu Purohit for Shashtipoorthi Shanthi Celebrations",
      "MetaDescription": "Celebrate Shashtipoorthi Shanthi with a Telugu purohit from Karishye. Get expert guidance and all necessary pooja items for a meaningful ceremony.",
      "H1Tag": "Shasthipoorthi Shanthi",
      "SuggestedURL": "https://www.karishye.co/poojas/telugu/shashtipoorthi-shanthi"
     },
     "saraswathi-homam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/saraswathi-homam",
      "Keywords": "Saraswathi Homam, Saraswathi Pooja, Saraswathi Homam Kit, Saraswathi Puja Samagri, Saraswathi Puja Packages",
      "MetaTitle": "Saraswathi Homam - Vedic Purohit for Saraswathi Pooja",
      "MetaDescription": "Book Telugu Pujari Services for Saraswathi Homam enhances intelligence and creativity, helping students and professionals overcome obstacles for success.",
      "H1Tag": "Saraswathi Homam"
     },
     "lakshmi-kubera-homam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/lakshmi-kubera-homam",
      "Keywords": "Sri Lakshmi Kubeara Homam, Lakshmi Kubera Puja, Lakshmi Kubera Pooja Packages",
      "MetaTitle": "Sri Lakshmi Kubera Homam and Puja for Wealth and Prosperity",
      "MetaDescription": "Boost wealth and prosperity with Sri Lakshmi Kubera Homam and Puja. Expert pujari services and kits are available across Telangana and Andhra Pradesh. Book now!",
      "H1Tag": "Sri Lakshmi Kubera Homam"
     },
     "maha-mryutyunjaya-homam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/maha-mryutyunjaya-homam",
      "Keywords": "Mryutyunjaya Homam Cost, Mryutyunjaya Puja Samagri, Mryutyunjaya Homam Packages, Pujari for Maha Mryutyunjaya Homam,",
      "MetaTitle": "Book Telugu pujari online to perform Maha Mryutyunjaya Homam",
      "MetaDescription": "Book a Telugu pujari online for Maha Mryutyunjaya Homam, performed to seek protection from ailments and ensure longevity across Telangana and Andhra Pradesh.",
      "H1Tag": "Mahamryutyujana Homam"
     },
     "ayushya-homam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/ayushya-homam",
      "Keywords": "Ayushya Homam Samagri Kit, Ayushya Puja Cost, Ayushya Mantra",
      "MetaTitle": "Telugu Pujari for Ayushya Homam and Mantra - Karishye",
      "MetaDescription": "Book a Telugu pujari for Ayushya Homam and Mantra performed to promote health and longevity during special occasions or for personal well-being.",
      "H1Tag": "Ayushya Homam"
     },
     "sudarshana-homam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/sudarshana-homam",
      "Keywords": "Sudarshana Homam Cost, Maha Sudarshana Homam Packages, Pujari for Sudarshana Homam",
      "MetaTitle": "Book Sudarshana Homam at Home or Temple in Hyderabad",
      "MetaDescription": "Book Sudarshana Homam at your home or temple to invoke divine protection and remove obstacles. Expert Telugu pujari services and puja kits are available.",
      "H1Tag": "Sudarshana Homam"
     },
     "shop-office-company-opening":{
      "PageURL": "https://www.karishye.co/poojas/telugu/shop-office-company-opening",
      "Keywords": "Office opening ceremony, pujari for shop opening pooja, office opening puja samagri, Office opening puja, ",
      "MetaTitle": "Pujari for New Shop or Office Opening Puja in Hyderabad",
      "MetaDescription": "Book a Telugu pujari for your new shop or office opening puja to attract success and prosperity. Expert services ensure a blessed and thriving beginning.",
      "H1Tag": "Shop Office Opening Company Puja"
     },
     "punyahavachanam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/punyahavachanam",
      "Keywords": "punyahavachanam samagri, punyahavachanam packages, purohit for punyahavachanam, punyahavachanam puja, ",
      "MetaTitle": "Telugu Purohit for Punyahavachanam Pooja in Hyderabad",
      "MetaDescription": "Book a Telugu Purohit for Punyahavachanam Pooja on Karishye. This sacred ritual purifies and blesses new beginnings. Available for home or temple.",
      "H1Tag": "Punyavachanam"
     },
     "samvathsarikam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/samvathsarikam",
      "Keywords": "Samvathsarikam, First year ceremony, Samvathsarikam Ceremony, Pandit for samvathsarikam, First year taddinam,",
      "MetaTitle": "Book Telugu Pandit for Samvathsarikam - First Year Ceremony",
      "MetaDescription": "Book a Telugu Brahmin or Non-Brahmin for Samvathsarikam with Karishye. Enjoy complete pooja materials and top-notch pandit service for the first-year ceremony.",
      "H1Tag": "Samvathsarikam - First Year Ceremony"
     },
     "aabhdhikam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/aabhdhikam",
      "Keywords": "Pandit for aabhdhikam, Taddinam, Death Anniversary, Shraddha, ",
      "MetaTitle": "Book Priest for Aabhdhikam Services in Hyderabad",
      "MetaDescription": "Book a priest for Aabhdhikam or Taddinam Services or on Karishye. This important ritual for honoring the deceased ensures peace & release for the departed soul",
      "H1Tag": "Aabhdhikam"
     },
     "masikam":{
      "PageURL": "https://www.karishye.co/poojas/telugu/masikam",
      "Keywords": "Maasikam Services, Pujari for Maasikam, Maasikam Cost, Maasikam Oona,",
      "MetaTitle": "Book Telugu Pujari for Maasikam Services - Karishye",
      "MetaDescription": "Book a Telugu Pujari for Maasikam services on Karishye. This ritual performed monthly for departed souls, ensures peace and spiritual upliftment."
     },
     "ganesha-for-navaratri-Pandals":{
      "PageURL": "https://www.karishye.co/poojas/telugu/ganesha-for-navaratri-Pandals",
      "Keywords": "Ganesha Idols, Pandal Decorators",
      "MetaTitle": "Buy Ecofriendly Ganesha Idols | Top Pandal Decorators ",
      "MetaDescription": "Shop eco-friendly Ganesha idols and find top pandal decorators at Karishye. Enhance your celebration with our expert pujari services for a truly special event!"
     },
      "ganesha-chaturdhi-kit-river-ganesha-10-inch":{
      "PageURL": "https://www.karishye.co/poojas/telugu/ganesha-chaturdhi-kit-river-ganesha-10-inch",
      "Keywords": "9 Inch Clay Ganesha Idol, Handcrafted Ganesh Idol, Ecofriendly Ganesh, Buy Clay Ganesh Idol, Ganesha Puja Kit, Ganesh Chaturthi,",
      "MetaTitle": "Buy Eco Friendly 9-Inch Ganesha Idol Online in Hyderabad",
      "MetaDescription": "Buy a 9-inch handcrafted river clay eco-friendly Ganesh idol for Ganesh Chaturthi, and get a set of 21 Ayurvedic herbs from Karishye online in Hyderabad."
     },
     "birthday-function":{
      "PageURL": "https://www.karishye.co/poojas/telugu/birthday-function",
      "Keywords": "Birthday Puja Ceremony, Janamdin Puja, ",
      "MetaTitle": "Pujari for Birthday Function - Janamdin Puja - Karishye ",
      "MetaDescription": "Get Pujari Services for Birthday Function through Karishye. Experience traditional rituals and blessings for a joyful and prosperous year ahead. Best prices!"
     },
     "pujaris":{
      "PageURL": "https://www.karishye.co/pujaris",
      "Keywords": "Telugu Pujari, Poojari near me, Poojari Services, Online Pandit for Puja, Pujari in Hyderabad, Purohit Service, Online Pooja Booking",
      "MetaTitle": "Book Telugu Pujari for Pooja Need | Online Purohit Near Me",
      "MetaDescription": "Book a Telugu Pujari online for your Pooja needs. Find experienced Purohits near you for all pujas, Homams, Birthday functions, Ganesh festival, etc.",
      "H1Tag": "Online Pujaris",
      "SuggestedURL": "https://www.karishye.co/online-pujaris"
     },
     "mybookings":{
      "PageURL": "https://www.karishye.co/mybookings",
      "Keywords": "My bookings, Pujari Booking",
      "MetaTitle": "My Bookings - Online Pujari Booking - Karishye"
     },
     "offerings":{
      "PageURL": "https://www.karishye.co/offerings",
      "Keywords": "Puja service, Poojari services, pandit services, purohit services, puja ceremonies",
      "MetaTitle": "Our Offerings - Puja and Poojari Services Online",
      "MetaDescription": "We offer a range of puja and pujari services for Hindu devotion throughout Telangana and Andhra Pradesh available online and offline, complete with a puja kit."
     },
     "contact":{
      "PageURL": "https://www.karishye.co/contact",
      "Keywords": "contact us",
      "MetaTitle": "Contact Us - Karishye Pujari and Pooja Services Online",
      "MetaDescription": "Karishye is the best online pooja booking portal for expert Telugu pujaris and complete pooja materials. Contact us for all your ceremonial needs!\r"
     },
     "landingpage":{
      "PageURL": "https://www.karishye.co)",
      "MetaTitle": "Book Telugu Pujari/Purohits for Puja or Homam in Hyderabad",
      "MetaDescription": "Book Telugu Pujari online for all types of pujas, homams, birthdays, and griha pravesh pooja ceremonies and puja samagri at the best price in Hyderabad, India."
     }
    }

    export default seoData;