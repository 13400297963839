const jsonData = {
  "annaprasana":  "/annaprasana_puja/image_1.png",
  "aksharabhyasam": "/aksharabhyasam/image_1.png",
  "arogya-homam": "/arogya_homa/image_1.jpg",
  "ayushya-homam": "/ayushya_homam/image_1.jpg",
  "aabhdhikam-one-bhoktha": "/abdhikam_one_bhokta/image_1.jpg",
  "aabhdhikam-three-bhotha": "/abdhikam_three_bhokta/image_1.png",
  "shankusthapana": "/bhoomi_puja/image_1.jpg",
  "chandi-homam": "/chandi_homam/image_1.png",
  "default-puja-image": "/default_puja_image.jpg",
  "ganapati-homam": "/ganapati_homam/image_1.jpg",
  "gruhapravesham":"/gruhapravesham/image_1.jpeg",
  "masikam": "/masikam/image_1.png",
  "rudrabhishekam": "/rudrabhishekam/image_1.png",
  "samvathsarikam": "/samvatsrikam/image_1.png",
  "saraswathi-homam":"/saraswati_homam/image_1.png",
  "satyanarayana-vratam":"/satyanarayana_vratam/image_1.jpg",
  "sudarshana-homam": "/sudarshana_homam/image_1.jpg"
};

  export default jsonData;