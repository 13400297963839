import React from "react";
import "./Navbar.scss";
import "../Menu/Menu";
import { BsFillTelephoneFill } from "react-icons/bs";
export default function Navbar() {
  const user = localStorage.getItem("user");
  return (
    <>
      <div className="headersec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 col-md-3 col-5 header__image">
              <a href={process.env.REACT_APP_WEBSITE_URL}>
                <div className="widget widget_media_image">
                  <div className="widget-content">
                    <img
                      src="../../assets/images/logo.png"
                      className="image wp-image-17 attachment-full size-full"
                      alt=""
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-10 col-md-9 col-7">
              <a href="/menu" className="hamburger">
                <img src="../../assets/images/hamburgermenu.png" alt="" />
              </a>
              <a href="/offerings" className="ritualbut">
                <div className="widget_text widget widget_custom_html">
                      Book Now
                </div>
              </a>
              <div className="headline">
                <img
                  src="../../assets/images/headerline.png"
                  alt="header-line-mobile"
                />
                <span><BsFillTelephoneFill/></span>
                <span>8121007481</span>
                <a className="our-offering-url" href="/offerings">
                  Our Offerings
                </a>
                {user&&(
                <a className="our-booking-url" href="/mybookings">
                  My Bookings
                </a>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
