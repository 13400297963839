import React, { useEffect, useState } from "react";
import "./MyBooking.scss";
import Navbar from "../../Component/Navbar/navbar";
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import axios from "axios";
import { BASE_URL } from "../../environments/config";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import seoData from "../../Seo";
export default function MyBooking() {
  const pageUrl = seoData["mybookings"]?.PageURL;
  const metaTitle = seoData["mybookings"]?.MetaTitle;
  const metaKeywords = seoData["mybookings"]?.Keywords;

  const [bookingList, setBookingList] = useState([]);
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchBookingList = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/booking/assigned-bookings?userId=${userId}`
        );
        setBookingList(response.data.data);
      } catch (err) {
        console.log("err", err);
      }
    };

    if (userId) {
      fetchBookingList();
    }
  }, [userId]);
  
  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="keywords" content={metaKeywords} />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <Navbar />
      <div className="newofferingsec mybooking-sec">
        <div className="container">
          <h1>My Bookings</h1>
          <div className="my-bookings-wrapper">
            <div className="bookings-box">
              <div className="accordion" id="myBookingsAccord"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-booking-container">
        <div className="accordion">
          {bookingList.map((list, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header">
                <div className="accordion-button collapsed my-booking-pujaname collapse-btn">
                  <div className="header-wrapper">
                    <div className="title-wrapper">
                      <img
                        src="assets/images/Karishye-logomybooking.png"
                        alt=""
                      />
                      <p className="booking-puja-name">
                        {list?.puja_model?.puja?.name}
                      </p>
                    </div>
                    <div className="see-details-btn">
                        <div
                          className="details-btn"
                          onClick={() => navigate(`/booking-detail?userId=${userId}&bookingId=${list?.id}`)}
                        >
                          See Details
                        </div>
                      </div>

                    </div>
                  </div>
                </h2>
                <div className="row mybooking-top-details">
                  <div className="col-lg-4 col-md-4 col-12">
                    <div className="booking-details-box">
                      <h6>
                        <span>
                          <img src="assets/images/user.png" alt="" />
                        </span>
                        {list?.name ? (
                          list.name
                        ) : list?.user?.name ? (
                          list.user.name
                        ) : (
                          <i>To Be Decided</i>
                        )}
                      </h6>
                      <p>Name</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12">
                    <div className="booking-details-box">
                      <h6>
                        <span>
                          <img src="assets/images/user.png" alt="" />
                        </span>
                        {list?.pujaris &&
                        list?.pujaris.name &&
                        list?.bookingStatus !== "pujari_rejected" ? (
                          list?.pujaris.name
                        ) : (
                          <i>To Be Decided</i>
                        )}
                      </h6>
                      <p>Pujari Name</p>
                    </div>
                  </div>

                <div className="col-lg-4 col-md-4 col-12">
                  <div className="booking-details-box">
                    <h6>
                      <span>
                        <img src="assets/images/rupee.png" alt="" />
                      </span>
                      {list?.bookingSellingPrice ? (
                        list?.bookingSellingPrice
                      ) : (
                        <i>To Be Decided</i>
                      )}
                    </h6>
                    <p>Final Cost</p>
                  </div>
                </div>
              </div>

              <div className="mybookings-status">
                <h2>Your Order Status</h2>
                <div className="mybookings-status-wrapper">
                  <div className="mybookings-satus-box">
                    <div
                      className={
                        list?.bookingStatus === "submitted"
                          ? "mybooking-satus-icon-wrap completed"
                          : "mybooking-satus-icon-wrap"
                      }
                    >
                      <img src="assets/images/mybookings-satus1.svg" alt="" />
                    </div>
                    <p>Booking Submitted</p>
                  </div>
                  <div className="mybookings-satus-box">
                    <div
                      className={
                        list?.bookingStatus === "plan_under_review"
                          ? "mybooking-satus-icon-wrap completed"
                          : "mybooking-satus-icon-wrap"
                      }
                    >
                      <img src="assets/images/mybookings-satus2.svg" alt="" />
                    </div>
                    <p>Booking Under Review</p>
                  </div>
                  <div className="mybookings-satus-box">
                    <div
                      className={
                        list?.bookingStatus === "plan_approved"
                          ? "mybooking-satus-icon-wrap completed"
                          : "mybooking-satus-icon-wrap"
                      }
                    >
                      <img src="assets/images/mybookings-satus3.svg" alt="" />
                    </div>
                    <p>Puja Plan Finalised</p>
                  </div>
                  <div className="mybookings-satus-box">
                    <div
                      className={
                        list?.bookingStatus === "puja_ended"
                          ? "mybooking-satus-icon-wrap completed"
                          : "mybooking-satus-icon-wrap"
                      }
                    >
                      <img src="assets/images/mybookings-satus5.svg" alt="" />
                    </div>
                    <p>Puja Completed</p>
                  </div>
                  <div className="mybookings-satus-box">
                    <div
                      className={
                        list?.bookingStatus === "pujari_amount_credited"
                          ? "mybooking-satus-icon-wrap completed"
                          : "mybooking-satus-icon-wrap"
                      }
                    >
                      <img src="assets/images/mybookings-satus4.svg" alt="" />
                    </div>
                    <p>Payment Completed</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
      <Whatsapp />
    </div>
  );
}