/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../environments/config";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';



const SignupModal = ({ loginInputModal, setLoginInputModal, hideLogin, data, frombooking, isUser }) => {
  const [signupFormData, setSignupFormData] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    surname: "",
  });

  const navigate = useNavigate();
  const phNum = localStorage.getItem("phoneNumber");

  useEffect(() => {
    setSignupFormData((prevData) => ({
      ...prevData,
      phoneNumber: phNum,
    }));
  }, [phNum]);

  const signupSubmitHandle = async (e) => {
    e.preventDefault();
    if (frombooking) {
      await handleCombinedSubmit();    //If we are login from puja page , while logging in we are also submitting the booking
    } else {
      await handleSignupSubmit();
      navigate("/");
    }
  };

  const handleCombinedSubmit = async () => {
    await handleSignupSubmit();
    await handleSubmit();                 //Here we are registering the user and then submitting the booking
  };

  const handleSubmit = async () => {
    const user = localStorage.getItem("userId");          //Submitting the pujs booking
    data.data.userId = user;
    try {
      const response = await fetch(
        `${BASE_URL}/booking/puja-booking?userId=${user}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) throw new Error("Booking failed");
      const result = await response.json();
      navigate("/booking-success");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSignupSubmit = async () => {
    const newErrors = {
      name: signupFormData.name ? "" : "Mandatory.",
      surname: signupFormData.surname ? "" : "Mandatory.",
    };
    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error);
    if (hasErrors) return;

    const payload = { data: signupFormData };
    try {
      const response = await fetch(`${BASE_URL}/users/create-new`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.data.id) {
          localStorage.setItem("userId", data.data.id);
          localStorage.setItem("user", signupFormData.name);
        }
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error in submitting form:", error);
    }
  };

  const hideLoginInput = () => {
    setLoginInputModal(!loginInputModal);
    setSignupFormData({
      name: "",
      surname: "",
      phoneNumber: phNum,
    });
    setErrors({
      name: "",
      surname: "",
    });
  };

  const handleSignupChange = (event) => {
    const { name, value } = event.target;
    setSignupFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? "" : "Mandatory.",
    }));
  };

  return (
    <div
      className="modal fade signup-form show"
      role="dialog"
      style={{ display: loginInputModal ? "flex" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content padding">
          <div className="modal-body">
            <h5 className="modal-title" id="sendopt">
              Enter your Details
            </h5>
            <button
              type="button"
              className="btn-close close-btn-loginregister"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                hideLoginInput();
                hideLogin();
              }}
            ></button>
            <form
              name="register-form"
              className="register-form"
              onSubmit={signupSubmitHandle}
            >
              <div className="form-group mb-3">
                <label
                  className="labeltitle"
                  htmlFor="first_name"
                  style={{ marginTop: "20px" }}
                >
                  First Name
                </label>
                <input
                  type="text"
                  value={signupFormData.name}
                  id="register_first_name"
                  onChange={handleSignupChange}
                  name="name"
                  className="form-control int-fill valid"
                />
                {errors.name && (
                  <label
                    id="register_first_name-error"
                    className="error"
                    htmlFor="register_first_name"
                    style={{ color: "red" }}
                  >
                    {errors.name}
                  </label>
                )}
              </div>
              <div className="form-group mb-3">
                <label className="labeltitle" htmlFor="last_name">
                  Last Name
                </label>
                <input
                  type="text"
                  id="register_last_name"
                  value={signupFormData.surname}
                  onChange={handleSignupChange}
                  name="surname"
                  className="form-control int-fill valid"
                />
                {errors.surname && (
                  <label
                    id="register_first_name-error"
                    className="error"
                    htmlFor="register_first_name"
                    style={{ color: "red" }}
                  >
                    {errors.surname}
                  </label>
                )}
              </div>
              <button type="submit" className="btn_continue">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

SignupModal.propTypes = {
  loginInputModal: PropTypes.bool.isRequired, // Expect loginInputModal to be a boolean and required
  setLoginInputModal: PropTypes.bool.isRequired, // Expect setLoginInputModal to be a function and required
  hideLogin: PropTypes.func.isRequired, // Expect hideLogin to be a function and required
};

export default SignupModal;
