import React, { useState} from "react";
import "./Menu.scss";
import { useNavigate } from "react-router-dom";
import LoginModal from "../Login/Login";

const Menu = () => {
  const frombooking= false;
  const [loginModal, setLoginModal] = useState(false);
  const [signupFormData, setSignupFormData] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
  });
  const user = localStorage.getItem("userId");

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
  };

  const hideLogin = () => {
    setLoginModal(!loginModal);
  };

  return (
    <div id="mySidenav" className="sidenav" style={{ width: "100%" }}>
      <div className="menubg">
      <button
          onClick={() => navigate(-1)}
          className="closebtn"
          aria-label="Close menu"
        >
          <img src="assets/images/close.png" alt="Close menu" />
        </button>
        <div className="menuvalign">
          <ul>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-664">
              <a href="/offerings">Book Now</a>
            </li>
            {user && (
              <li className="log-in-booking menu-item menu-item-type-post_type menu-item-object-page menu-item-730">
                <a href="/mybookings">My Bookings</a>
              </li>
            )}
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-725">
              <a href="/offerings">Our Offerings</a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-377">
              <a href="/pujaris">Who is a Pujari?</a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-269">
              <a href="/about">About Us</a>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-378">
              <a href="/contact">Contact Us</a>
            </li>
          </ul>
          {user ? (
            <ul className="user-signup-wrapper">
              <li>
                <a
                  href="profile"
                  className="logout-karishye"
                >
                  My Account
                </a>
              </li>
              <li role="button" className="active" onClick={handleLogout} >
                <a href="/" className="logout-krishya">
                  Logout
                </a>
              </li>
            </ul>
          ) : (
            <ul className="user-signup-wrapper">
              <li className="active" onClick={hideLogin}>
                <a
                  className="logout-karishye"
                >
                  Login/SignUp
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
      <LoginModal frombooking={frombooking} setSignupFormData={setSignupFormData} signupFormData={signupFormData} setLoginModal={setLoginModal} loginModal={loginModal} style={{ display: loginModal ? "flex" : "none" }}/>
    </div>
  );
};

export default Menu;
