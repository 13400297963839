import React from "react";
import "./WhoIsPooja.scss";
import Navbar from "../../Component/Navbar/navbar";
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import { Helmet } from 'react-helmet';
import seoData from "../../Seo";
export default function WhoIsPooja() {

  const pageUrl = seoData["pujaris"]?.PageURL;
  const metaTitle = seoData["pujaris"]?.MetaTitle;
  const metaDescription = seoData["pujaris"]?.MetaDescription;
  const metaKeywords = seoData["pujaris"]?.Keywords;
  const h1=seoData["pujaris"]?.H1Tag;

  return (
    <>
     <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <h1 className="hiddenTag">{h1}</h1>
      <Navbar />
      <div className="WhoIsPooja">
        <div className="priestpgfstcontsec">
          <div className="centertext herosection-text">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h1>Who is a Pujari?</h1>
                  <p>
                    Someone who takes us from nothing to profoundness. One who
                    has dedicated his life to studying a certain branch of the
                    vedas and the art of performing pujas – “the science of
                    consecrating energy into an inanimate object using mantras
                    and conducting prayers for the betterment of the society or
                    a family”
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="priestpgsndcontsec">
          <div className="container">            
            <p>
              Being a pujari is not just about possessing the knowledge, the
              memory of the scriptures and the know-how of performing rituals.
              While this is essential, there is so much more.
            </p>
            <p>
              As a pujari, one comes in regular contact with energy and uses
              vedic methods to derive benefits from them. He accepts donations
              from families in the form of rice, money etc, and helps these
              individuals come out of a certain issue or help them move closer
              to a particular objective. But in return, he reaches home, fasts
              at times, performs vedic mantra chantings for multiples of
              thousands of times, in order to surrender the karma that he
              himself has accumulated in the process.
            </p>
            <div className="midpriestheight" />
            <p>
              In order to be able to do this, the pujari himself must be in a
              certain state of righteousness, a certain state of ‘tapas’. He
              must be practising constant rituals of his own in order to ensure
              that these activities do not negatively impact his own personal
              life. It is therefore said that certain homas or special types of
              pujas can only be performed by priests with the relevant ‘tapas’
              and ‘anushtana’.
            </p>
            <p>
              So in essence, being a good pujari is not just about education or
              experience. It is about an inner mental state. A state of
              calmness, of kindness towards others, a state of selflessness
              while praying for the betterment of others. As Karishye, we wish
              and humbly submit ourselves to almighty that he guides us to such
              people to work along side us.
            </p>
          </div>
        </div>

        <div className="priestpgthrdcontsec">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h2>Recruiting a Pujari @ Karishye</h2>

                <ul>
                  <li>
                    We accept applications from anyone irrespective of age,
                    school of study etc
                  </li>
                  <li>
                    We check via references to understand their skill and
                    experience
                  </li>
                  <li>
                    We are constantly looking for priests who know their vedas
                    well and are calm and soft natured
                  </li>
                  <li>
                    We believe that a right pujari can play a big role in the
                    spiritual upliftment of families and the society
                  </li>
                </ul>
              </div>
              <div className="col-lg-5 desktopview">
                <img
                  src="assets/images/image01.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="priestpgfrthcontsec">
          <div className="container">
            <h2>Pujaris @ Karishye</h2>
            <p>
              We are excited to provide various benefits to the pujaris working
              along side us and supporting us.
            </p>
            <div className="priestboxsec">
              <div className="desktopview">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="sglepriestboxsec">
                          <p>
                            Health insurance with coverage for all their
                            immediate family members
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sglepriestboxsec">
                          <p>
                            Empowering the pujaris with technology and
                            connecting with customers from all parts of the
                            world
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="sglepriestboxsec">
                          <p>
                            Continuous learning programs for those who wish to
                            study more
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sglepriestboxsec">
                          <p>
                            Mentorship, opportunities and career guidance to
                            pass outs from vedic schools
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="priestpgfifthcontsec">
          <div className="">
            <h2>
              ‘Gurur bramha, Gurur Vishnuhu, Gurur devo maheshwaraha. Gurur
              sakshat parabramha, tasmaisri guravenamaha’.
            </h2>
            <div className="priestbottomsection">
              <img
                src="assets/images/hand.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Whatsapp/>
    </>
  );
}
